import { useContext, useEffect, useState } from 'react';
import CButton from '../../../shared/forms/Button';
import { usePaystackPayment } from 'react-paystack';
import { PlanContext } from '../plans/usePlanContext';
import { errorToast } from '../../../utils/helpers';
import MastercardIcon from '../../../assets/images/payment/cards/mastercard.svg';
import VerveCardIcon from '../../../assets/images/payment/cards/verve.svg';
import VisaCardIcon from '../../../assets/images/payment/cards/visa.svg';
import ConfirmationModal from '../../../shared/forms/ConfirmationModal';
import { Card, Col, Row } from 'react-bootstrap';
import SadSmileyIcon from '../../../assets/images/dashboard/sad-smiley.svg';
import {
  AdminAttributeType,
  UserAttributeType,
} from '../../../utils/types/auth_types';
import {
  CardAttributeType,
  CardType,
  PaystackOnSuccessResponseType,
} from '../../../utils/types/subscription_types';
import { PaystackProps } from 'react-paystack/dist/types';
import { Constants } from '../../../utils/constants';
import { cardLast4 } from '../../../utils/stringManipulation';
import Loader from '../../../shared/Loader';
import CModal from '../../../shared/forms/Modal';

const CardDetails = ({ user }: { user: UserAttributeType }) => {
  const [cards, setCards] = useState<CardAttributeType[]>([]);
  const [refetchCards, setRefetchCards] = useState(false);
  const [id, setId] = useState<number>();
  const [reference, setReference] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { editCard, cardList, deleteCard, addCard } = useContext(PlanContext);

  useEffect(() => {
    (async () => {
      setShowLoader(false);
      setLoading(true);

      if (reference) {
        try {
          await addCard(reference);

          const result = await cardList();
          setLoading(false);

          if (!result || result.length === 0) return;

          const attributes = result.map(
            ({ attributes }: CardType) => attributes
          );
          setCards(attributes);

          return;
        } catch (error) {
          errorToast(error as string);
          setLoading(false);
        }
      }

      const result = await cardList();
      setLoading(false);

      const attributes = result.map(({ attributes }: CardType) => attributes);
      setCards(attributes);
    })();
  }, [refetchCards]);

  const maxCount = 3;
  const tokenizationAmount = 10000;

  const config: PaystackProps = {
    email: user.email,
    amount: tokenizationAmount,
    metadata: {
      userType: user.is_admin
        ? Constants.UserType.Admin
        : Constants.UserType.Employee,
      additionalInfo: 'card',
    } as any,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY as string,
  };

  const initializePayment = usePaystackPayment(config);

  const triggerPaystack = () => {
    initializePayment(onPaystackSuccess, onPaystackClose);
  };

  const onPaystackSuccess = (response: PaystackOnSuccessResponseType) => {
    setShowLoader(true);
    setTimeout(() => {
      setReference(response.reference);
      setRefetchCards(!refetchCards);
    }, 3000);
  };

  const onPaystackClose = () => {
    setRefetchCards(!refetchCards);
  };

  const cardIcon = (brand: string) => {
    if (brand === Constants.CardBrands.Mastercard) return MastercardIcon;
    if (brand === Constants.CardBrands.Verve) return VerveCardIcon;

    return VisaCardIcon;
  };

  const prepareCardEditData = (cardId: number) => {
    setShowEditModal(true);
    setId(cardId);
  };

  const prepareCardDeleteData = (cardId: number) => {
    setShowDeleteModal(true);
    setId(cardId);
  };

  const setAsDefault = async () => {
    setLoadingEdit(true);

    try {
      await editCard(id as number);

      setReference('');
      setRefetchCards(!refetchCards);
    } catch (error) {
      /* empty */
    } finally {
      setLoadingEdit(false);
      setShowEditModal(false);
      setShowEditModal(false);
    }
  };

  const handleDeleteCard = async () => {
    setLoadingDelete(true);
    try {
      await deleteCard(id as number);
      setReference('');
      setRefetchCards(!refetchCards);
    } catch (error) {
      /* empty */
    } finally {
      setLoadingDelete(false);
      setShowDeleteModal(false);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <strong className="text-dark">{`Card details (${cards.length}/${maxCount})`}</strong>
        {(user as AdminAttributeType).has_cards && cards.length < 3 && (
          <CButton small onClick={triggerPaystack}>
            Add new card
          </CButton>
        )}
      </div>
      <p>Your default card is used to run your Corna subscription</p>

      <br />
      <div>
        <ConfirmationModal
          show={showEditModal}
          title="Set as default"
          cta="Update"
          type="card"
          loading={loadingEdit}
          onClose={() => setShowEditModal(false)}
          onClick={setAsDefault}
        />
        <ConfirmationModal
          show={showDeleteModal}
          cta="Delete"
          type="card"
          loading={loadingDelete}
          onClose={() => setShowDeleteModal(false)}
          onClick={handleDeleteCard}
        />
        <Row>
          <CModal
            title=" "
            show={showLoader}
            onClose={() => setShowLoader(false)}
          >
            <Loader />
          </CModal>
          {loading ? (
            <Loader />
          ) : cards.length === 0 ? (
            <Col md={4}>
              <Card className="home-card payment-card">
                <Card.Body>
                  <div className="text-center mt-4">
                    <img src={SadSmileyIcon} alt="Empty Icon" />
                    <p className="home-text__desc mt-2">
                      You have not added any card
                    </p>
                    <i className="small">
                      you will be refunded when you add a new card
                    </i>
                    <div className="mt-2">
                      <CButton small onClick={triggerPaystack}>
                        Add a new card
                      </CButton>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ) : (
            cards.map(card => {
              return (
                <Col md={4} className="mb-4 mb-md-0" key={card.id}>
                  <Card
                    className={`payment-card ${
                      card.has_expired ? 'payment-card__expired' : ''
                    }`}
                  >
                    <Card.Body className="payment-card__details">
                      <div className="d-flex justify-content-between payment-card__details-data">
                        <p className="c-btn__small--text">Card name</p>
                        {card.default && (
                          <CButton
                            small
                            smallText
                            color={`${
                              card.has_expired ? 'expired' : 'inverted'
                            }`}
                          >
                            Primary
                          </CButton>
                        )}
                      </div>
                      <small className="payment-card__details-name">
                        {card.name}
                      </small>
                      <p className="mt-3 card-title">
                        {cardLast4(card.last4)}
                      </p>
                      <div className="d-flex justify-content-between payment-card__details-data">
                        <p
                          className={`c-btn__small--text ${
                            card.has_expired ? '' : ''
                          }`}
                        >{`${card.has_expired ? 'Expired' : 'Expiry'}`}</p>
                        <img src={cardIcon(card.brand)} alt={card.name} />
                      </div>
                      <small className="payment-card__details-expiry-date">
                        {card.expiry_date}
                      </small>
                      <hr
                        className={`${
                          card.has_expired
                            ? 'payment-card-hr__expired'
                            : 'payment-card-hr'
                        }`}
                      />
                      <div className="d-flex justify-content-end">
                        {!card.default && (
                          <CButton
                            smallNoLoader
                            smallText
                            onClick={() => prepareCardEditData(card.id)}
                          >
                            Set as primary
                          </CButton>
                        )}
                        <CButton
                          smallNoLoader
                          smallText
                          color="transparent"
                          onClick={() => prepareCardDeleteData(card.id)}
                        >
                          Remove
                        </CButton>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          )}
        </Row>
      </div>
    </div>
  );
};

export default CardDetails;
